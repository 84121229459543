import {
	type ErrorResponse,
	isRouteErrorResponse,
	useParams,
	useRouteError,
} from '@remix-run/react'
import { PiHandPalm } from 'react-icons/pi'
import { getErrorMessage } from '#app/utils/misc'
import Hero from './hero'

type StatusHandler = (info: {
	error: ErrorResponse
	params: Record<string, string | undefined>
}) => JSX.Element | null

export function GeneralErrorBoundary({
	defaultStatusHandler = ({ error }) => (
		<p>
			{error.status} {error.data}
		</p>
	),
	statusHandlers,
	unexpectedErrorHandler = (error) => <p>{getErrorMessage(error)}</p>,
}: {
	defaultStatusHandler?: StatusHandler
	statusHandlers?: Record<number, StatusHandler>
	unexpectedErrorHandler?: (error: unknown) => JSX.Element | null
}) {
	const error = useRouteError()
	const params = useParams()

	if (typeof document !== 'undefined') {
		console.error(error)
	}

	return (
		<div className="container flex items-center justify-center p-20 text-h2">
			{isRouteErrorResponse(error)
				? (statusHandlers?.[error.status] ?? defaultStatusHandler)({
						error,
						params,
					})
				: unexpectedErrorHandler(error)}
		</div>
	)
}

export default function GenericErrorBoundary() {
	const error = useRouteError()
	console.error('ErrorBoundary.GenericErrorBoundary', error)

	// Don't forget to typecheck with your own logic.
	// Any value can be thrown, not just errors!
	let errorTitle = 'Unknown error'
	let errorMessage =
		"Something we didn't expect happened that resulted in this ugly screen, sorry!"

	if (isRouteErrorResponse(error)) {
		// This is a route error response. It's a special type of error that
		// comes from the server. It has a status code and a message.
		errorTitle = error.status.toString()
		if (error.statusText) {
			errorMessage = error.statusText
		}

		if (error.data?.error) {
			if (error.data.error.title) {
				errorTitle = error.data.error.title
			}
			if (error.data.error.message) {
				errorMessage = error.data.error.message
			}
		}
	}

	return (
		<Hero icon={PiHandPalm} title={errorTitle} description={errorMessage}>
			<div className="mt-4 flex items-center justify-center gap-x-6">
				<a
					href="https://moonhub-internal.slack.com/archives/C050FLXNZEW"
					target="_blank"
					className="text-sm font-semibold text-gray-600 dark:text-gray-500"
					rel="noreferrer"
				>
					Contact support via slack #internal-product-feedback
				</a>
			</div>
		</Hero>
	)
}
